<template>
	<div class="ProjectView flex flex--column" :class="elmClasses">

		<!-- dev grid -->
		<MhDevGrid
			innerClasses="maxWidth maxWidth--sectionInner"
			color="blue"
			opacity="0.25"
			columns="8"
			gap="30px"
		></MhDevGrid>

		<!-- wrapper keeps the footer offscreen -->
		<div class="minHeight XXXminHeight--100vh">

			<!-- mainHeader -->
			<GmSection
				class="app__nav GmSection--noMaxWidth"
				:section="{ bgColor : pageBgColor }"
				XXXpostUseStickyHeader="true"
				v-if="viewIsReady">
				<GmMainHeader></GmMainHeader>
				<GmFullscreenMedia
					v-if="_.get( post, 'acf.isActiveFullscreenMediaSlider' )"
					:slides="_.get( post, 'acf.fullscreenMediaSlider' )"
				></GmFullscreenMedia>
			</GmSection>

			<!-- headline mit projekt title -->
			<GmSection class="GmSection--noPaddingBelow GmSection--noPaddingTopForNextSection" v-if="post">
				<GmSpacer
					lines="3"
				></GmSpacer>

				<GmHeadline
					class="font font--uppercase"
					:title="'<a href=/referenzen>Referenzen › </a> ' + post.title.rendered"
					fontSize="small"
					linkUrl=""
					linkText=""
				></GmHeadline>
				<GmSpacer
					lines="1"
				></GmSpacer>
			</GmSection>

			<!-- contentModules -->
			<GmSection
				v-for="(section, i) in sections"
				:section="section"
				:index="i"
				:key="i">
				<div
				v-for="(contentModule, j) in section.contentModules"
				:key="j"
				v-bind="contentModule"
				:is="contentModule.acf_fc_layout"
				></div>
			</GmSection>

			<!-- Projekt Nav -->
			<GmSection class="bgColor bgColor--almostBlack" v-if="post">
				<GmProjectFooterNav
					firstLinkText="Alle Referenzen"
					firstLinkUrl="/referenzen"
					secondLinkText="Nächstes Projekt"
					:secondLinkTitle="_.get( post, 'adjacentLinks.next.postTitle', null)"
					:secondLinkUrl="_.get( post, 'adjacentLinks.next.link', null)"
				></GmProjectFooterNav>
			</GmSection>

			<!-- 404 -->
			<GmSection v-if="is404">
				<Gm404 />
			</GmSection>

		</div>

		<!-- GmMainFooter -->
		<GmSection
			:section="{ bgColor : app.acfOptions.footerBgColor }"
			v-if="app.acfOptions && viewIsReady">
			<GmMainFooter
				:firstText="app.acfOptions.footerFirstCol"
				:secondText="app.acfOptions.footerSecondCol"
				:thirdText="app.acfOptions.footerThirdCol"
				:fourthText="app.acfOptions.footerFourthCol"
			></GmMainFooter>
		</GmSection>

	</div>
</template>

<script>
	// @ is an alias to /src
	import { EventBus } from '@/event-bus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import ViewMixin from './View.mixin.js'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'

	import GmMainHeader from '@/components/GmMainHeader.vue'
	import GmSection from '@/components/GmSection.vue'
	import GmHeadline from '@/components/GmHeadline.vue'
	import GmTeaserImagesInColums from '@/components/GmTeaserImagesInColums.vue'
	import GmText from '@/components/GmText.vue'
	import GmTestimonial from '@/components/GmTestimonial.vue'
	import GmChapterText from '@/components/GmChapterText.vue'
	import GmAnchorNav from '@/components/GmAnchorNav.vue'
	import GmImage from '@/components/GmImage.vue'
	import GmTwoImages from '@/components/GmTwoImages.vue'
	import GmProjectFooterNav from '@/components/GmProjectFooterNav.vue'
	import GmCompanyLocations from '@/components/GmCompanyLocations.vue'
	import GmMainFooter from '@/components/GmMainFooter.vue'
	import GmSlider from '@/components/GmSlider.vue'
	import GmSliderFullBleed from '@/components/GmSliderFullBleed.vue'
	import GmProcessColumns from '@/components/GmProcessColumns.vue'
	import GmImageColumns from '@/components/GmImageColumns.vue'
	import GmSpacer from '@/components/GmSpacer.vue'
	import GmProjectsTeasers from '@/components/GmProjectsTeasers.vue'
	import Gm404 from '@/components/Gm404.vue'
	import GmFullscreenMedia from '@/components/GmFullscreenMedia.vue'

	export default {
		name: 'ProjectView',
		components: {
			MhDevGrid,
			GmMainHeader,
			GmSection,
			GmHeadline,
			GmTeaserImagesInColums,
			GmText,
			GmTestimonial,
			GmChapterText,
			GmAnchorNav,
			GmImage,
			GmTwoImages,
			GmProjectFooterNav,
			GmCompanyLocations,
			GmMainFooter,
			GmSlider,
			GmSliderFullBleed,
			GmProcessColumns,
			GmImageColumns,
			GmSpacer,
			GmProjectsTeasers,
			Gm404,
			GmFullscreenMedia,
		},
		mixins: [
			RestHandler,
			ViewMixin,
		],
		directives: {},
		props: {},
		data() {
			return {
				post: null,
				is404: false,
			}
		},
		watch: {
			post: {
				handler: function( to, from ) {
					const bgColor = this.post.acf.bgColor ? this.post.acf.bgColor : null
					const textColor = this.post.acf.textColor ? this.post.acf.textColor : null
					EventBus.$emit('bgColorChanged', bgColor)
					EventBus.$emit('textColorChanged', textColor)
				},
				deep: true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			pageBgColor() {
				const post = this.post
				const bgColor = post ? post.acf.bgColor : false

				return bgColor
			},
			sections() {
				const post = this.post
				let sections = post ? post.acf.sections : false

				return sections
			},
		},
		methods: {
			sectionClasses( section ) {
				const isSticky = this._.find( section.contentModules, (o) => o.acf_fc_layout === 'GmAnchorNav' ) ? true : false
				let classes = []

				if( isSticky ) classes.push( 'sticky' )

				return classes
			},
		},
		mounted() {
			// fetch pages
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/posts',
				params : {
					slug : this.$route.params.slug
				},
				callback : (response) => {
					const result = response.data.result[0]
					const post = result

					if( post && post.acf ){
						this.post = result
						this.viewBackgroundColor = this.post.acf.bgColor ? this.post.acf.bgColor : null
						this.viewTextColor = this.post.acf.textColor ? this.post.acf.textColor : null
					}else{
						this.is404 = true
						//this.viewBackgroundColor = this.post.acf.bgColor ? this.post.acf.bgColor : null
						//this.viewTextColor = this.post.acf.textColor ? this.post.acf.textColor : null
					}

					// set title
					if( result ){
						this.title = this.post.title.rendered
					}

					this.viewIsReady = true
				},
			})
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/atoms.less";

	.ProjectView {
		position: relative;
	}
</style>
